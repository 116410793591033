import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const DworzecPKPAleksandrowkaPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO
        title="Aleksandrówka / zabytkowy Dworzec PKP Dębe Wielkie
"
      />
      <ReturnToPrevPage to={'/gminy/debe-wielkie'}>
        Materiały dotyczące gminy Dębe Wielkie
      </ReturnToPrevPage>
      <Header1>Aleksandrówka / zabytkowy Dworzec PKP Dębe Wielkie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Linia kolejowa z Warszawy na wschód prowadząca swój szlak przez powiat
          Miński liczy sobie już 154 lata. Dębe Wielkie były na tej trasie
          przystankiem. W 1909 roku spłonął pierwotny budynek. Obecny datuje się
          na 1912 rok.
        </p>
        <p>
          Znaczenie linii kolejowej jest niebagatelne. Choć dzieli miasta, jak
          Mińsk Mazowiecki to jednak jest ogromnym motorem napędowym rozwoju.
          Znaczenie też miały kolosalne w czasie konfliktów zbrojnych i walki o
          niepodległość. Stacja była świadkiem dramatycznych wydarzeń
          szczególnie w czasie II Wojny Światowej.
        </p>
        <p>
          Już 3 września spalony był dwój w Dębem Wielkim, a 7 września kolejne
          niemieckie bombardowania doprowadziły do przerwania linii. Tory
          naprawiane były doraźnie przez oddział saperów. Mimo naprawy pociągi
          nie mogły jechać gdyż potok ludzi pieszo i wszelkimi środkami
          ewakuując się w kierunku wschodnim całkowicie blokowały przejazd przez
          skrzyżowanie torów z drogą. Żołnierze byli zmuszeni zatrzymać
          uciekających, aby przepuścić pociąg.
        </p>
        <p>
          Tak wspomina wydarzenia z 10 września na stacji w Dębem Wielkim, pan
          Zbigniew Miękus. Jechał on z matką i ze swoim kolegą pociągiem
          ewakuacyjnym w kierunku Siedlec.
        </p>
        <p>
          „Nasz pociąg ewakuacyjny dotarł pod Warszawę. Staliśmy dwa, trzy dni,
          nim przejechaliśmy na drugą stronę Wisły. Przez te dni byliśmy
          dosłownie od świtu do zmroku pod bombami. Niemieckie lotnictwo
          dosłownie pastwiło się nad bezbronną Warszawą. Z dachu naszego wagonu
          stojącego tuż przed dworcem Warszawa – Zachodnia, widziałem na własne
          oczy jak Niemieccy lotnicy bezkarnie ostrzeliwali i bombardowali naszą
          stolicę. W tych dniach Warszawa nie była broniona przez polskie
          lotnictwo lub artylerię przeciwlotniczą. Jedynie z transportów
          wojskowych ostrzeliwali się żołnierze z karabinów maszynowych, a
          widziałem, że poszczególni żołnierze klęcząc na dachu wagonu, w swej
          bezsilności, strzelali z ręcznej broni. Prawdę powiedziawszy,
          widziałem dwa czy trzy polskie samoloty jak toczyły walkę powietrzną
          nad Warszawą. Dużo nie mogli zdziałać. Zestrzelenie jednego samolotu
          niemieckiego wywołało ogromny entuzjazm u nas chłopców i żołnierzy.
        </p>
        <p>
          Nareszcie nocą przejechaliśmy przez most na Wiśle w Warszawie. Po
          minięciu stolicy nasz transport nabrał szybkości. Dojechaliśmy przed
          stację Dębe Wielkie koło Mińska Mazowieckiego. Pociągi stanęły.
          Ogłoszono alarm. Trwał nalot niemieckiego lotnictwa szturmowo –
          nurkującego, tak zwanych „Sztukasów”. Bombardowali stację. Jedna z
          bomb uderzyła w połączenie dwóch wagonów kolei elektrycznej,
          podmiejskiej. Dwa wagony pełne trupów! Masakra! Zabici na korytarzu
          stali, tak byli stłoczeni i poplątani. Z okien zwisały głowy zabitych,
          osmalonych. Ciekła im krew z ust i uszu. Z wagonów, przez dolne szpary
          płynęła czerwona struga krwi, na całą szerokość zamkniętych drzwi! W
          słońcu mieniła się, ta struga krwi, jak rubinowy płat lśniącego
          jedwabiu wypływający z maszyny farbiarskiej.
        </p>
        <p>
          Widziałem jak na wysokim peronie skacze na jednej nodze kobieta w
          szoku, wołając swoje dziecko. Była tak zszokowana, że nie czuła, nie
          widziała, że ma urwaną nogę poniżej pachwiny. Sanitariusze podjęli ją
          pod ramiona i położyli na peronowej ławce. Dopiero wtedy zemdlała,
          straciła przytomność. Zaczęto ją opatrywać, tamować upływającą krew.
        </p>
        <p>
          Zginęło wtedy bardzo wiele ludzi. Niemiecki lotnik odpalił trzy bomby.
          Jedna wybuchła przed pociągiem, na wysokim peronie, druga w złączeniu
          dwóch wagonów, a trzecia za pociągiem, w rowie tuż przy torze. W dwóch
          wagonach byli wszyscy zabici, a w następnych dwóch wielu zabitych, a
          bardzo wielu rannych od podmuchu, odłamków bomb, betonu, a najwięcej
          od odłamków szkła z okien wagonów osobowych. Był to widok makabryczny!
          Tylu zabitych, rannych i okaleczonych na całe życie. Wielu straciło
          oczy od rozbitego szkła.
        </p>
        <p>
          Ksiądz w komży, blady jak chusta, błogosławił umierających, udzielał z
          sanitariuszami i harcerzami pomocy, opatrywał rannych.
        </p>
        <p>
          Chciałbym zaznaczyć, że po przejechaniu Wisły nie widzieliśmy już
          prawie wojska. Również na stacji ani w jej pobliżu nie było nawet
          jednego żołnierza w mundurze. Dużo makabrycznych scen w życiu, w
          czasie tej wojny widziałem, ale takiej masakry bezbronnych ludzi nie
          widziałem. Widok ten nie tylko u mnie wywołał torsje. Byłem chory,
          obolały kilka dni. Do dziś ten widok „stoi mi w oczach”.
        </p>
        <p>
          Trzeba odszukać tych lotników i surowo ich ukarać za tę zbrodnię.
          Widziałem, nie jeden raz, jak niemieccy lotnicy strzelali z broni
          maszynowej do stert słomy, w których chowali się cywile, dzieci z
          rodzicami. Widziałem z dachu wagonu, jak uganiali się za przerażonymi,
          uciekającymi po polu kobietami, trzymającymi za ręce swe maleńkie
          dzieci. Strzelali do nich, zniżając się do nich bezkarnie na kilka
          metrów nad ziemią. Wprawiali bezbronnych ludzi w bezgraniczny
          przestrach. Po prostu urządzali sobie zabawę w polowanie na ludzi.
          Widzieli bardzo dokładnie, jaki sobie obrali cel do swych zbrodniczych
          zabaw. Może dlatego po tych wypadkach przestałem marzyć by zostać
          lotnikiem.
        </p>
        <p>
          W naszym transporcie było tylko dwóch śmiałków, którzy w czasie
          nalotów nie uciekali pod wagony lub w pole, lecz wdrapali się na dach
          wagonu, gdzie było dużo widać. Tymi śmiałkami był mój kolega Stanisław
          Deryng „Wójo” i ja. Matki błagały nas byśmy się schronili pod wagony
          lub do rowu. Zauważyliśmy jednak, że bardzo dużo rannych było od
          odłamków kamieni, żelaza lub szkła. Uciekinierzy i żołnierze
          załatwiali swe potrzeby fizjologiczne pod osłoną wagonów. Wokół torów
          i na samych torach było bardzo brudno. Jak my to mówiliśmy „brzydko
          pachniało”. Na dachu natomiast było czysto, widno. Daleko było widać
          co się wokół działo. Niemieccy lotnicy tak nisko latali, że
          rozpoznawałem ich rysy twarzy w kabinach ich samolotów. Widoczność
          mieli bardzo dobrą, nikt im nie przeszkadzał. Widzieli dokładnie do
          kogo prowadzą ogień.”
        </p>
        <p>
          Fot. Archiwum Gminy Dębe Wielkie oraz Piotr VASCO Wasilkowski, autorem
          tekstu Bogdan Kuć
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/debe-wielkie/dworzec-pkp/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/debe-wielkie/dworzec-pkp/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DworzecPKPAleksandrowkaPage
